// ContentContext.js

import React, { createContext, useState } from 'react';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [contentInput, setContentInput] = useState({
    file: null,
    text: '',
  });

  return (
    <ContentContext.Provider value={{ contentInput, setContentInput }}>
      {children}
    </ContentContext.Provider>
  );
};
