// TopicsList.js
import React, { useState, useEffect, useContext } from 'react';
import { db, auth } from './Firebase';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  Timestamp,
  query,
  where,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import TopicCard from './TopicCard';

const TopicsList = ({ compact = false }) => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('date');
  const [searchTerm, setSearchTerm] = useState('');
  const [showAllTopics, setShowAllTopics] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // Fetch topics from Firestore
  const fetchTopics = async () => {
    setLoading(true);
    try {
      if (!currentUser) {
        setTopics([]);
        setLoading(false);
        return;
      }

      const topicsRef = collection(db, 'topics');
      const q = query(topicsRef, where('userId', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);
      const topicsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTopics(topicsData);
    } catch (error) {
      console.error('Error fetching topics:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // Navigate to the topic creation page
  const handleAddTopic = async () => {
    try {
      const newTopic = {
        name: 'New Topic',
        createdAt: Timestamp.now(),
        userId: currentUser.uid,
        keyTerms: [],
      };
      const docRef = await addDoc(collection(db, 'topics'), newTopic);
      navigate(`/topics/${docRef.id}`);
      fetchTopics(); // Refresh the list
    } catch (error) {
      console.error('Error adding topic:', error);
    }
  };

  // Delete handler
  const handleDeleteTopic = async (topicId) => {
    try {
      await deleteDoc(doc(db, 'topics', topicId));
      fetchTopics(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting topic:', error);
    }
  };

  const sortTopics = (topicsArray) => {
    switch (sortBy) {
      case 'date':
        return [...topicsArray].sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      case 'name':
        return [...topicsArray].sort((a, b) => a.name.localeCompare(b.name));
      case 'terms':
        return [...topicsArray].sort((a, b) => (b.keyTerms?.length || 0) - (a.keyTerms?.length || 0));
      default:
        return topicsArray;
    }
  };

  const filteredTopics = topics
    .filter(topic => 
      topic.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const sortedTopics = sortTopics(filteredTopics);
  const mostRecentTopic = sortedTopics[0];
  const remainingTopics = sortedTopics.slice(1);

  return (
    <div className="bg-sand/50 rounded-lg">
      <div className="p-4">
        {loading ? (
          <div className="flex justify-center items-center h-20">
            <div className="animate-spin rounded-full h-6 w-6 border-2 border-olive-light border-t-transparent"></div>
          </div>
        ) : sortedTopics.length === 0 ? (
          <div className="text-center py-4">
            <p className="text-olive-dark">No topics yet</p>
            {!compact && (
              <button
                onClick={handleAddTopic}
                className="mt-2 text-sm text-copper-dark hover:text-copper-light transition-colors"
              >
                Create your first topic
              </button>
            )}
          </div>
        ) : (
          <div className="space-y-4">
            {/* Most Recent Topic */}
            {mostRecentTopic && (
              <div>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-olive-dark">Recent Topic</h3>
                  <div className="flex items-center gap-2">
                    {!compact && (
                      <button
                        onClick={handleAddTopic}
                        className="text-sm text-copper-dark hover:text-copper-light transition-colors flex items-center gap-1"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                        </svg>
                        Add New
                      </button>
                    )}
                  </div>
                </div>
                <TopicCard
                  topic={mostRecentTopic}
                  onRequestDelete={() => handleDeleteTopic(mostRecentTopic.id)}
                  compact={true}
                />
              </div>
            )}

            {/* Previous Topics Collapse */}
            {remainingTopics.length > 0 && (
              <div>
                <button
                  onClick={() => setShowAllTopics(!showAllTopics)}
                  className="w-full text-left text-sm text-olive-dark hover:text-olive-light transition-colors flex items-center gap-2"
                >
                  <svg
                    className={`w-4 h-4 transition-transform ${showAllTopics ? 'rotate-90' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                  Show Previous Topics ({remainingTopics.length})
                </button>

                {showAllTopics && (
                  <div className="mt-4 space-y-4 pl-4 border-l-2 border-olive-light/30">
                    {remainingTopics.map((topic) => (
                      <TopicCard
                        key={topic.id}
                        topic={topic}
                        onRequestDelete={() => handleDeleteTopic(topic.id)}
                        compact={true}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopicsList;
