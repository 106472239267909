// Home.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from './Firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { AuthContext } from './AuthProvider';
import TopicsList from './TopicsList';

// Update the file size constant to match the server's limit
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB to match server limit

// Add this utility function
const fetchWithRetry = async (url, options, maxRetries = 3) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await fetch(url, options);
      
      // If we get a 503, wait and retry
      if (response.status === 503) {
        if (i === maxRetries - 1) {
          throw new Error('Server is temporarily unavailable. Please try again in a few minutes.');
        }
        await new Promise(resolve => setTimeout(resolve, (i + 1) * 2000));
        continue;
      }
      
      return response;
    } catch (error) {
      if (i === maxRetries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, (i + 1) * 2000));
    }
  }
};

const Home = () => {
  // State management
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [keyTerms, setKeyTerms] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [editingTermIndex, setEditingTermIndex] = useState(null);
  const [editForm, setEditForm] = useState({
    term: '',
    definition: '',
    importance: '',
    example: '',
  });
  const [topicName, setTopicName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadController, setUploadController] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);

  // File handling
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        setError(`File size must be less than ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
        return;
      }

      if (
        selectedFile.type === 'application/pdf' ||
        selectedFile.type === 'text/plain' ||
        selectedFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ) {
        setFile(selectedFile);
        setError(null);
      } else {
        setError('Please upload a PDF, TXT, or PPTX file.');
      }
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      if (
        selectedFile.type === 'application/pdf' ||
        selectedFile.type === 'text/plain' ||
        selectedFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ) {
        setFile(selectedFile);
        setError(null);
      } else {
        setError('Please upload a PDF, TXT, or PPTX file.');
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  // Process content (either file or text)
  const processContent = async (content) => {
    try {
      setLoading(true);
      const response = await fetchWithRetry('https://gol-server-98369bb74a3f.herokuapp.com/process-text', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) throw new Error('Failed to process content.');

      const data = await response.json();
      setKeyTerms(data.key_terms);
      setCurrentStep(2);
    } catch (error) {
      setError(error.message || 'An error occurred while processing content.');
    } finally {
      setLoading(false);
    }
  };

  // Handle initial content submission
  const handleSubmit = async () => {
    if (!file && !text) {
      setError('Please upload a file or enter text.');
      return;
    }

    if (!currentUser) {
      saveState();
      navigate('/login', { state: { from: location.pathname } });
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setUploadProgress(0);

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        const controller = new AbortController();
        setUploadController(controller);

        try {
          const response = await fetch('https://gol-server-98369bb74a3f.herokuapp.com/upload-file/', {
            method: 'POST',
            body: formData,
            signal: controller.signal,
            headers: {
              'Accept': 'application/json',
            },
          });

          if (!response.ok) {
            if (response.status === 413) {
              throw new Error('File too large. Please try a smaller file.');
            }
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.detail || 'Failed to upload file.');
          }

          const data = await response.json();
          setKeyTerms(data.key_terms);
          setCurrentStep(2);
        } catch (error) {
          if (error.name === 'AbortError') {
            throw new Error('Upload cancelled.');
          }
          throw error;
        }
      } else {
        await processContent(text);
      }
    } catch (error) {
      console.error('Upload error:', error);
      setError(error.message || 'An error occurred while processing your content.');
    } finally {
      setLoading(false);
      setUploadController(null);
    }
  };

  // Function to save current state
  const saveState = () => {
    const state = {
      file,
      text,
    };
    sessionStorage.setItem('contentInput', JSON.stringify(state));
  };

  // Function to restore state after login
  const restoreState = () => {
    const savedState = sessionStorage.getItem('contentInput');
    if (savedState) {
      const { file: savedFile, text: savedText } = JSON.parse(savedState);
      setFile(savedFile);
      setText(savedText);
      sessionStorage.removeItem('contentInput');
    }
  };

  // Restore state when component mounts
  useEffect(() => {
    restoreState();

    // If the user is returning from login and has input, process the content
    if (currentUser && (file || text) && currentStep === 1) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // Term management functions
  const handleEditTerm = (index) => {
    const term = keyTerms[index];
    setEditForm({
      term: term.term,
      definition: term.definition,
      importance: term.importance,
      example: term.example || '',
    });
    setEditingTermIndex(index);
  };

  const handleDeleteTerm = (index) => {
    const updatedTerms = [...keyTerms];
    updatedTerms.splice(index, 1);
    setKeyTerms(updatedTerms);
  };

  const handleSaveEdit = () => {
    if (
      !editForm.term.trim() ||
      !editForm.definition.trim() ||
      !editForm.importance.trim()
    ) {
      setError('Term, definition, and importance are required.');
      return;
    }

    const updatedTerms = [...keyTerms];
    const newTerm = {
      term: editForm.term.trim(),
      definition: editForm.definition.trim(),
      importance: editForm.importance.trim(),
      example: editForm.example.trim(),
    };

    if (editingTermIndex === -1) {
      updatedTerms.push(newTerm);
    } else {
      updatedTerms[editingTermIndex] = newTerm;
    }

    setKeyTerms(updatedTerms);
    setEditingTermIndex(null);
    setEditForm({
      term: '',
      definition: '',
      importance: '',
      example: '',
    });
  };

  const handleAddNewTerm = () => {
    setEditForm({
      term: '',
      definition: '',
      importance: '',
      example: '',
    });
    setEditingTermIndex(-1);
  };

  // Placeholder functions for generating tests and flashcards
  const generateTests = async (keyTerms) => {
    // Implement your logic to generate tests
    // For example, call an API endpoint
    return [
      {
        questions: keyTerms.map((term) => ({
          question: `What is ${term.term}?`,
          answer: term.definition,
        })),
      },
    ];
  };

  const generateFlashcards = async (keyTerms) => {
    // Implement your logic to generate flashcards
    // For example, call an API endpoint
    return keyTerms.map((term) => ({
      term: term.term,
      definition: term.definition,
    }));
  };

  // Save topic to Firestore
  const saveTopic = async () => {
    if (!topicName.trim()) {
      setError('Please enter a topic name.');
      return;
    }

    if (!currentUser) {
      setError('You must be logged in to save a topic.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Generate tests and flashcards
      const tests = await generateTests(keyTerms);
      const flashcards = await generateFlashcards(keyTerms);

      const docRef = await addDoc(collection(db, 'topics'), {
        name: topicName.trim(),
        createdAt: Timestamp.now(),
        keyTerms: keyTerms,
        userId: currentUser.uid, // Associate topic with user ID
        tests: tests,
        flashcards: flashcards,
      });

      setTopicName('');
      setKeyTerms([]);
      setCurrentStep(1);
      navigate(`/topics/${docRef.id}`);
    } catch (error) {
      console.error('Error saving topic:', error);
      setError('Error saving topic.');
    } finally {
      setLoading(false);
    }
  };

  const cancelUpload = () => {
    if (uploadController) {
      uploadController.abort();
      setUploadController(null);
      setLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="min-h-screen bg-sand">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {!currentUser && currentStep === 1 && (
          <div className="mb-8 text-center">
            <h1 className="text-4xl font-bold text-olive-dark mb-3">
              Transform Your Notes Into Active Learning
            </h1>
            <p className="text-xl text-olive-light max-w-2xl mx-auto mb-6">
              Upload your study material and get instant flashcards, quizzes, and practice tests.
            </p>
          </div>
        )}

        {currentUser && currentStep === 1 && (
          <div className="mb-8">
            <h3 className="text-2xl font-bold text-olive-dark mb-4">Recent Topics</h3>
            <TopicsList compact={true} />
          </div>
        )}

        {/* Step 1: Input Content */}
        {currentStep === 1 && (
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-bold text-olive-dark mb-4">
              Step 1: Input Your Study Material
            </h2>

            <div className="space-y-4">
              {/* File Upload Section */}
              <div
                className={`flex flex-col items-center p-4 border-2 border-dashed rounded-lg transition-colors ${
                  isDragOver ? 'border-copper-light bg-copper-light/10' : 'border-olive-light hover:border-copper-light'
                }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <label className="w-full">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".pdf,.txt,.pptx"
                    className="hidden"
                  />
                  <div className="flex flex-col items-center cursor-pointer">
                    <svg className="w-9 h-9 text-olive-light mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <span className="text-olive-dark font-medium">
                      Drop files here or click to upload
                    </span>
                    <span className="text-xs text-gray-500 mt-1">
                      Supported formats: PDF, TXT, PPTX
                    </span>
                  </div>
                </label>
                {file && (
                  <div className="mt-3 text-xs text-olive-dark">
                    Selected file: {file.name}
                  </div>
                )}
              </div>

              {loading && file && (
                <div className="mt-4">
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-copper-dark h-2.5 rounded-full transition-all duration-300"
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                  <p className="text-sm text-olive-dark mt-2 text-center">
                    Uploading: {uploadProgress}%
                  </p>
                </div>
              )}

              <div className="text-center text-olive-dark font-medium">or</div>

              {/* Text Input Section */}
              <div>
                <textarea
                  rows="6"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Paste your text here..."
                  className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none"
                />
              </div>

              <button
                onClick={handleSubmit}
                disabled={loading || (!file && !text)}
                className={`w-full py-2 px-4 rounded-lg text-white font-medium transition-colors ${
                  loading || (!file && !text)
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-copper-dark hover:bg-copper-light'
                }`}
              >
                {loading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin h-4 w-4 mr-2" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Processing...
                  </span>
                ) : (
                  'Process Content'
                )}
              </button>

              {loading && (
                <button
                  onClick={cancelUpload}
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                >
                  Cancel Upload
                </button>
              )}
            </div>
          </div>
        )}

        {/* Step 2: Review Terms */}
        {currentStep === 2 && keyTerms.length > 0 && (
          <div className="space-y-6">
            {/* Save Topic Section - Moved to top */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-olive-dark mb-2">
                    Topic Name
                  </label>
                  <input
                    type="text"
                    value={topicName}
                    onChange={(e) => setTopicName(e.target.value)}
                    placeholder="Enter topic name"
                    className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light text-black"
                  />
                </div>
                <button
                  onClick={saveTopic}
                  disabled={loading}
                  className={`px-6 py-3 rounded-lg text-white font-medium transition-colors h-[46px] mt-7 ${
                    loading
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-copper-dark hover:bg-copper-light'
                  }`}
                >
                  {loading ? 
                    <span className="flex items-center">
                      <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                      </svg>
                      Saving...
                    </span>
                    : 'Save Topic'
                  }
                </button>
              </div>
            </div>

            {/* Key Terms Section */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-olive-dark">Review and Edit Terms</h2>
                <button
                  onClick={handleAddNewTerm}
                  className="px-4 py-2 bg-copper-dark text-white rounded-lg hover:bg-copper-light transition-colors flex items-center gap-2"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                  </svg>
                  Add Term
                </button>
              </div>

              <div className="space-y-4">
                {keyTerms.map((termObj, index) => (
                  <div key={index} className="bg-sand/30 rounded-lg p-6">
                    <div className="flex justify-between items-start mb-4">
                      <h3 className="text-xl font-semibold text-olive-dark">
                        {termObj.term}
                      </h3>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditTerm(index)}
                          className="p-2 text-olive-light hover:text-olive-dark transition-colors"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDeleteTerm(index)}
                          className="p-2 text-red-500 hover:text-red-700 transition-colors"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-olive-dark mb-1">
                          Definition
                        </label>
                        <p className="text-gray-700">{termObj.definition}</p>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-olive-dark mb-1">
                          Importance
                        </label>
                        <p className="text-gray-700">{termObj.importance}</p>
                      </div>

                      {termObj.example && (
                        <div>
                          <label className="block text-sm font-medium text-olive-dark mb-1">
                            Example
                          </label>
                          <p className="text-gray-700">{termObj.example}</p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Edit Term Modal */}
        {editingTermIndex !== null && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl shadow-xl max-w-lg w-full p-6">
              <h3 className="text-2xl font-bold text-olive-dark mb-6">
                {editingTermIndex === -1 ? 'Add New Term' : 'Edit Term'}
              </h3>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-olive-dark mb-1">
                    Term
                  </label>
                  <input
                    type="text"
                    value={editForm.term}
                    onChange={(e) => setEditForm({ ...editForm, term: e.target.value })}
                    placeholder="Enter term"
                    className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-olive-dark mb-1">
                    Definition
                  </label>
                  <textarea
                    value={editForm.definition}
                    onChange={(e) => setEditForm({ ...editForm, definition: e.target.value })}
                    placeholder="Enter definition"
                    rows="3"
                    className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-olive-dark mb-1">
                    Importance
                  </label>
                  <textarea
                    value={editForm.importance}
                    onChange={(e) => setEditForm({ ...editForm, importance: e.target.value })}
                    placeholder="Explain importance"
                    rows="3"
                    className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-olive-dark mb-1">
                    Example (Optional)
                  </label>
                  <textarea
                    value={editForm.example}
                    onChange={(e) => setEditForm({ ...editForm, example: e.target.value })}
                    placeholder="Add an example"
                    rows="3"
                    className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none"
                  />
                </div>
              </div>

              <div className="mt-6 flex space-x-4">
                <button
                  onClick={handleSaveEdit}
                  className="flex-1 py-2 px-4 bg-copper-dark text-white rounded-lg hover:bg-copper-light transition-colors"
                >
                  Save
                </button>
                <button
                  onClick={() => setEditingTermIndex(null)}
                  className="flex-1 py-2 px-4 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg flex items-center justify-between">
            <div className="flex items-center">
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>{error}</span>
            </div>
            {error.includes('timed out') && (
              <button
                onClick={() => {
                  setError(null);
                  handleSubmit();
                }}
                className="ml-4 px-4 py-2 bg-copper-dark text-white rounded-lg 
                           hover:bg-copper-light transition-colors text-sm"
              >
                Try Again
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;