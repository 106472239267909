// PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

const PrivateRoute = ({ children }) => {
  const { currentUser, authLoading } = useContext(AuthContext);

  if (authLoading) {
    // You can return a loading indicator here if desired
    return null;
  }

  return currentUser ? children : <Navigate to="/login" />;
};

export default PrivateRoute;