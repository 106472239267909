// TopicDetails.js

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Added useNavigate
import { db } from './Firebase';
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import { AuthContext } from './AuthProvider';

const TopicDetails = () => {
  const { topicId } = useParams();
  const navigate = useNavigate(); // Initialize navigate
  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingTermIndex, setEditingTermIndex] = useState(null);
  const [editForm, setEditForm] = useState({
    term: '',
    definition: '',
    importance: '',
    example: '',
  });
  const [materialType, setMaterialType] = useState('practice-test');
  const [difficulty, setDifficulty] = useState('medium');
  const [numQuestions, setNumQuestions] = useState(5);
  const [studyMaterials, setStudyMaterials] = useState(null);
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const [error, setError] = useState(null);

  const { currentUser } = useContext(AuthContext);

  // Fetch topic data from Firestore
  const fetchTopic = async () => {
    setLoading(true);
    try {
      if (!currentUser) {
        setError('You must be logged in to view this topic.');
        setLoading(false);
        return;
      }

      const docRef = doc(db, 'topics', topicId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const topicData = docSnap.data();
        if (topicData.userId === currentUser.uid) {
          setTopic({ id: docSnap.id, ...topicData });
        } else {
          setError('You do not have permission to view this topic.');
        }
      } else {
        setError('Topic not found.');
      }
    } catch (error) {
      console.error('Error fetching topic:', error);
      setError('Error fetching topic.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId, currentUser]);

  // Handle editing key terms
  const handleEditTerm = (index) => {
    const term = topic.keyTerms[index];
    setEditForm({
      term: term.term,
      definition: term.definition,
      importance: term.importance,
      example: term.example || '',
    });
    setEditingTermIndex(index);
  };

  const handleDeleteTerm = async (index) => {
    try {
      const termToDelete = topic.keyTerms[index];
      await updateDoc(doc(db, 'topics', topicId), {
        keyTerms: arrayRemove(termToDelete),
      });
      fetchTopic();
    } catch (error) {
      console.error('Error deleting term:', error);
      setError('Error deleting term.');
    }
  };

  const handleSaveEdit = async () => {
    if (
      !editForm.term.trim() ||
      !editForm.definition.trim() ||
      !editForm.importance.trim()
    ) {
      setError('Term, definition, and importance are required.');
      return;
    }

    try {
      const updatedTerm = {
        term: editForm.term.trim(),
        definition: editForm.definition.trim(),
        importance: editForm.importance.trim(),
        example: editForm.example.trim(),
      };

      if (editingTermIndex === -1) {
        // Add new term
        await updateDoc(doc(db, 'topics', topicId), {
          keyTerms: arrayUnion(updatedTerm),
        });
      } else {
        // Update existing term
        const termToDelete = topic.keyTerms[editingTermIndex];
        await updateDoc(doc(db, 'topics', topicId), {
          keyTerms: arrayRemove(termToDelete),
        });
        await updateDoc(doc(db, 'topics', topicId), {
          keyTerms: arrayUnion(updatedTerm),
        });
      }

      setEditingTermIndex(null);
      setEditForm({
        term: '',
        definition: '',
        importance: '',
        example: '',
      });
      fetchTopic();
    } catch (error) {
      console.error('Error saving term:', error);
      setError('Error saving term.');
    }
  };

  const handleAddNewTerm = () => {
    setEditForm({
      term: '',
      definition: '',
      importance: '',
      example: '',
    });
    setEditingTermIndex(-1);
  };

  // Generate study materials
  const generateStudyMaterials = async () => {
    if (!topic || !topic.keyTerms || topic.keyTerms.length === 0) {
      setError('No key terms available to generate study materials.');
      return;
    }

    try {
      setLoadingMaterials(true);
      setError(null);

      const response = await fetch(
        `https://gol-server-98369bb74a3f.herokuapp.com/generate-${materialType}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            terms: topic.keyTerms,
            material_type: materialType,
            difficulty,
            num_questions: parseInt(numQuestions),
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to generate study materials');
      }

      const data = await response.json();
      console.log('API Response:', data); // For debugging

      // Store the raw response as is
      setStudyMaterials(data);
      setError(null);

    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Error generating study materials.');
      setStudyMaterials(null);
    } finally {
      setLoadingMaterials(false);
    }
  };

  const startTest = () => {
    if (!studyMaterials) return;
    
    if (materialType === 'flashcards' && !studyMaterials.flashcards?.length) {
      setError('No flashcards available');
      return;
    }
    
    if (materialType === 'practice-test' && !studyMaterials.practice_test?.questions) {
      setError('No practice test questions available');
      return;
    }
    
    if (materialType === 'quiz' && !studyMaterials.quiz?.questions) {
      setError('No quiz questions available');
      return;
    }

    // Navigate to test with the raw materials
    navigate(`/topics/${topicId}/test`, { 
      state: { 
        materials: studyMaterials,
        type: materialType,
        difficulty: difficulty
      } 
    });
  };

  return (
    <div className="min-h-screen bg-sand">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-olive-light border-t-transparent"></div>
          </div>
        ) : error ? (
          <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
            {error}
          </div>
        ) : topic ? (
          <div className="space-y-8">
            {/* Topic Header */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <h2 className="text-3xl font-bold text-olive-dark">{topic.name}</h2>
              <p className="text-gray-600 mt-2">
                Created on: {topic.createdAt.toDate().toLocaleDateString()}
              </p>
            </div>

            {/* Study Materials Generation Section - Moved up and made more compact */}
            <div className="bg-white rounded-xl shadow-lg p-4 mb-6">
              <div className="flex flex-wrap items-end gap-4">
                <div className="flex-1 min-w-[200px]">
                  <label className="block text-sm font-medium text-olive-dark mb-1">
                    Generate
                  </label>
                  <select
                    value={materialType}
                    onChange={(e) => setMaterialType(e.target.value)}
                    className="w-full p-2 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light bg-white text-olive-dark"
                    defaultValue="practice-test"
                  >
                    <option value="practice-test">Practice Test</option>
                    <option value="flashcards">Flashcards</option>
                    <option value="quiz">Quiz</option>
                  </select>
                </div>

                <div className="w-32">
                  <label className="block text-sm font-medium text-olive-dark mb-1">
                    Difficulty
                  </label>
                  <select
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                    className="w-full p-2 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light bg-white text-olive-dark"
                    defaultValue="medium"
                  >
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>

                <div className="w-32">
                  <label className="block text-sm font-medium text-olive-dark mb-1">
                    Questions
                  </label>
                  <input
                    type="number"
                    value={numQuestions}
                    onChange={(e) => setNumQuestions(e.target.value)}
                    min="3"
                    max="20"
                    className="w-full p-2 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light text-olive-dark"
                    defaultValue={5}
                  />
                </div>

                <button
                  onClick={generateStudyMaterials}
                  disabled={loadingMaterials}
                  className={`px-6 py-2 rounded-lg text-white font-medium transition-colors ${
                    loadingMaterials
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-copper-dark hover:bg-copper-light'
                  }`}
                >
                  {loadingMaterials ? (
                    <span className="flex items-center">
                      <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Generating...
                    </span>
                  ) : (
                    'Generate'
                  )}
                </button>
              </div>

              {/* Study Materials Display */}
              {studyMaterials && (
                <div className="mt-4 border-t border-gray-200 pt-4">
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="text-lg font-medium text-olive-dark">
                      Preview
                    </h4>
                    <button
                      onClick={startTest}
                      className="px-4 py-2 bg-copper-dark text-white rounded-lg hover:bg-copper-light transition-colors"
                    >
                      Start {materialType === 'flashcards' ? 'Study Session' : 'Test'}
                    </button>
                  </div>
                  <div className="grid gap-3">
                    {materialType === 'practice-test' && studyMaterials.practice_test?.questions && (
                      studyMaterials.practice_test.questions.map((question, index) => (
                        <div key={index} className="bg-sand/30 rounded-lg p-3">
                          <p className="text-olive-dark mb-1">
                            <span className="font-medium">Q{index + 1}:</span> {question.question}
                          </p>
                          <p className="text-olive-dark text-sm">
                            <span className="font-medium">A:</span> {question.correctAnswer}
                          </p>
                        </div>
                      ))
                    )}
                    {materialType === 'quiz' && studyMaterials.quiz?.questions && (
                      studyMaterials.quiz.questions.map((question, index) => (
                        <div key={index} className="bg-sand/30 rounded-lg p-3">
                          <p className="text-olive-dark mb-1">
                            <span className="font-medium">Q{index + 1}:</span> {question.question}
                          </p>
                          <p className="text-olive-dark text-sm">
                            <span className="font-medium">A:</span> {question.correctAnswer}
                          </p>
                        </div>
                      ))
                    )}
                    {materialType === 'flashcards' && studyMaterials.flashcards && (
                      studyMaterials.flashcards.map((flashcard, index) => (
                        <div key={index} className="bg-sand/30 rounded-lg p-3">
                          <p className="font-medium text-olive-dark">
                            {flashcard.front}
                          </p>
                          <p className="text-olive-dark text-sm mt-1">
                            {flashcard.back}
                          </p>
                        </div>
                      ))
                    )}
                    {!studyMaterials[materialType === 'practice-test' ? 'practice_test' : materialType]?.questions && 
                     !studyMaterials.flashcards && (
                      <p className="text-olive-dark text-center">No study materials available</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Key Terms Section */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-2xl font-bold text-olive-dark">Key Terms</h3>
                <button
                  onClick={handleAddNewTerm}
                  className="px-4 py-2 bg-copper-dark text-white rounded-lg hover:bg-copper-light transition-colors"
                >
                  Add New Term
                </button>
              </div>

              <div className="grid gap-6">
                {topic.keyTerms.map((termObj, index) => (
                  <div
                    key={index}
                    className="bg-sand/30 rounded-lg p-6 hover:shadow-md transition-shadow"
                  >
                    <div className="flex justify-between items-start mb-4">
                      <h4 className="text-xl font-semibold text-olive-dark">
                        {termObj.term}
                      </h4>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEditTerm(index)}
                          className="p-2 text-olive-light hover:text-olive-dark transition-colors"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDeleteTerm(index)}
                          className="p-2 text-red-500 hover:text-red-700 transition-colors"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-olive-dark mb-1">
                          Definition
                        </label>
                        <p className="text-gray-700">{termObj.definition}</p>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-olive-dark mb-1">
                          Importance
                        </label>
                        <p className="text-gray-700">{termObj.importance}</p>
                      </div>

                      {termObj.example && (
                        <div>
                          <label className="block text-sm font-medium text-olive-dark mb-1">
                            Example
                          </label>
                          <p className="text-gray-700">{termObj.example}</p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Edit Term Modal */}
            {editingTermIndex !== null && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                <div className="bg-white rounded-xl shadow-xl max-w-lg w-full p-6">
                  <h3 className="text-2xl font-bold text-olive-dark mb-6">
                    {editingTermIndex === -1 ? 'Add New Term' : 'Edit Term'}
                  </h3>

                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-olive-dark mb-1">
                        Term
                      </label>
                      <input
                        type="text"
                        value={editForm.term}
                        onChange={(e) =>
                          setEditForm({ ...editForm, term: e.target.value })
                        }
                        className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light"
                        placeholder="Enter term"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-olive-dark mb-1">
                        Definition
                      </label>
                      <textarea
                        value={editForm.definition}
                        onChange={(e) =>
                          setEditForm({ ...editForm, definition: e.target.value })
                        }
                        rows="3"
                        className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none"
                        placeholder="Enter definition"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-olive-dark mb-1">
                        Importance
                      </label>
                      <textarea
                        value={editForm.importance}
                        onChange={(e) =>
                          setEditForm({ ...editForm, importance: e.target.value })
                        }
                        rows="3"
                        className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none"
                        placeholder="Explain importance"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-olive-dark mb-1">
                        Example (Optional)
                      </label>
                      <textarea
                        value={editForm.example}
                        onChange={(e) =>
                          setEditForm({ ...editForm, example: e.target.value })
                        }
                        rows="3"
                        className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none"
                        placeholder="Add an example"
                      />
                    </div>
                  </div>

                  <div className="mt-6 flex space-x-4">
                    <button
                      onClick={handleSaveEdit}
                      className="flex-1 py-2 px-4 bg-copper-dark text-white rounded-lg hover:bg-copper-light transition-colors"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditingTermIndex(null)}
                      className="flex-1 py-2 px-4 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TopicDetails;