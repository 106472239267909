// Navigation.js
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from './Firebase';
import { AuthContext } from './AuthProvider';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const generateBreadcrumbs = () => {
    const paths = location.pathname.split('/').filter(Boolean);
    if (paths.length === 0) return null;

    return (
      <div className="px-4 py-2 bg-sand/50">
        <div className="max-w-7xl mx-auto flex items-center space-x-2 text-sm">
          <Link to="/" className="text-olive-dark hover:text-olive-light">
            Home
          </Link>
          {paths.map((path, index) => {
            const url = `/${paths.slice(0, index + 1).join('/')}`;
            const isLast = index === paths.length - 1;
            
            return (
              <React.Fragment key={url}>
                <svg
                  className="h-5 w-5 text-olive-light"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                {isLast ? (
                  <span className="text-copper-dark font-medium">{path}</span>
                ) : (
                  <Link
                    to={url}
                    className="text-olive-dark hover:text-olive-light"
                  >
                    {path}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-olive-dark bg-opacity-75 shadow-lg sticky top-0 z-50 backdrop-blur-md">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            {/* Logo/Brand */}
            <Link to="/" className="flex items-center">
              <span className="text-sand text-xl font-bold">Gol</span>
              <span className="text-xs text-sand ml-2">(your overachieving AI study buddy)</span>
            </Link>
          </div>

          <div className="flex items-center">
            {currentUser ? (
              <nav className="hidden md:flex items-center space-x-4">
                <Link
                  to="/"
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    location.pathname === '/'
                      ? 'bg-olive-light text-white'
                      : 'text-sand hover:bg-olive-light/50 hover:text-white'
                  }`}
                >
                  Topics
                </Link>
                <Link
                  to="/new-topic"
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    location.pathname === '/new-topic'
                      ? 'bg-olive-light text-white'
                      : 'text-sand hover:bg-olive-light/50 hover:text-white'
                  }`}
                >
                  Create New Topic
                </Link>
                <div className="relative" ref={userMenuRef}>
                  <button
                    onClick={() => setShowUserMenu(!showUserMenu)}
                    className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-sand hover:bg-olive-light/50 hover:text-white focus:outline-none"
                  >
                    <span className="mr-2">{currentUser.email}</span>
                    <svg
                      className={`h-4 w-4 transition-transform duration-200 ${
                        showUserMenu ? 'transform rotate-180' : ''
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>

                  {/* Dropdown Menu */}
                  {showUserMenu && (
                    <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Sign out
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </nav>
            ) : (
              <nav className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="px-4 py-2 text-sm font-medium text-sand hover:text-white transition-colors duration-200"
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="px-4 py-2 rounded-md text-sm font-medium bg-copper-light text-white hover:bg-copper-dark transition-colors duration-200"
                >
                  Sign Up
                </Link>
              </nav>
            )}

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button className="p-2 rounded-md text-sand hover:text-white focus:outline-none">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {generateBreadcrumbs()}
    </div>
  );
};

export default Navigation;