// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import './index.css';
import AutoLogin from './Authentication/AutoLogin';
import DarkModeButton from './DarkModeButton';

import Home from './Home';
import Login from './Authentication/Login';
import UploadProcessing from './UploadProcessing';
import TopicsList from './TopicsList';
import TopicDetails from './TopicDetails';
import SignUp from './Authentication/SignUp';
import PrivateRoute from './PrivateRoute';
import Navigation from './Navigation';
import { AuthProvider } from './AuthProvider';
import { ContentProvider } from './ContentContext';
import TestTaker from './TestTaker';

export default function App() {
  return (
    <AuthProvider>
      <ContentProvider>
        <Router>
          <div className="bg-white dark:bg-black text-gray-900 dark:text-white min-h-screen">
            <Analytics />
            <AutoLogin />
            {/* <DarkModeButton /> */}
            <Navigation />
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/upload-processing" element={<UploadProcessing />} />
              <Route path="/new-topic" element={<Home />} />
              <Route path="/" element={<Home />} /> {/* Make home accessible */}
              
              {/* Protected routes */}
              <Route
                path="/topics"
                element={
                  <PrivateRoute>
                    <TopicsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/topics/:topicId"
                element={
                  <PrivateRoute>
                    <TopicDetails />
                  </PrivateRoute>
                }
              />
              {/* Add new test route */}
              <Route
                path="/topics/:topicId/test"
                element={
                  <PrivateRoute>
                    <TestTaker />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </Router>
      </ContentProvider>
    </AuthProvider>
  );
}
