import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Update to useNavigate
import { getAuth } from 'firebase/auth'; // Import Firebase Auth
import { getStorage, ref, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage

const UploadProcessing = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  useEffect(() => {
    const processFile = async () => {
      const auth = getAuth();
      const user = auth.currentUser; // Get current user
      const uploadedFile = localStorage.getItem('uploadedFile');

      if (user && uploadedFile) {
        const storage = getStorage(); // Initialize Firebase storage
        const fileRef = ref(storage, `uploads/${user.uid}/${uploadedFile}`);

        // Perform processing with the uploaded file
        const fileURL = await getDownloadURL(fileRef);
        console.log('Processing file:', fileURL);

        // Clear the file from localStorage once processed
        localStorage.removeItem('uploadedFile');

        // Redirect to a success or dashboard page after processing
        navigate('/dashboard'); // Redirect using useNavigate
      }
    };

    processFile();
  }, [navigate]); // Update to use navigate in the dependency array

  return <div>Processing your file, please wait...</div>;
};

export default UploadProcessing;
