import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../Firebase'; // Import the initialized auth object
import { onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged from firebase/auth

const AutoLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    if (initialCheckDone || location.pathname === '/contact-us') {
      return;
    }

    const remember = localStorage.getItem('rememberMe');
    if (remember && remember === 'true') {
      const unsubscribe = onAuthStateChanged(auth, user => {
        if (user) {
          navigate('/');
          setInitialCheckDone(true);
        }
      });

      return () => unsubscribe();
    }
  }, [navigate, initialCheckDone, location.pathname]);

  return null;
};

export default AutoLogin;