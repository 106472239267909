import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { auth } from '../Firebase';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const redirectPath = location.state?.from || '/';
      navigate(redirectPath);
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password.');
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      const redirectPath = location.state?.from || '/';
      navigate(redirectPath);
    } catch (error) {
      console.error('Error logging in with Google:', error);
    }
  };

  return (
    <div className="min-h-screen bg-sand flex items-center justify-center px-4">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
        <div className="flex flex-col items-center">
          {/* App Icon */}
          <FontAwesomeIcon 
            icon={faBook} 
            className="text-olive-dark w-12 h-12 mb-4"
          />
          <h1 className="text-3xl font-bold text-olive-dark text-center">Welcome Back</h1>
          {error && <p className="mt-2 text-red-600 text-center">{error}</p>}
        </div>
        
        <form onSubmit={handleLogin} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-olive-light placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-copper-light focus:border-copper-light"
              />
            </div>
            <div>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-olive-light placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-copper-light focus:border-copper-light"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-olive-dark hover:bg-olive-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-light transition-colors duration-200"
            >
              Sign in
            </button>
          </div>
        </form>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>

          <button
            onClick={handleGoogleLogin}
            className="mt-4 w-full flex items-center justify-center py-2 px-4 border border-copper-dark rounded-md shadow-sm text-sm font-medium text-copper-dark bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-copper-light transition-colors duration-200"
          >
            <FontAwesomeIcon 
              icon={faGoogle} 
              className="h-5 w-5 mr-2"
            />
            Google
          </button>
        </div>

        {/* Create Account Link */}
        <div className="text-center text-sm">
          <span className="text-gray-500">Don't have an account? </span>
          <Link 
            to="/signup" 
            className="font-medium text-copper-dark hover:text-copper-light transition-colors duration-200"
          >
            Create one now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;