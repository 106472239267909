// TestTaker.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const QUESTION_TYPES = {
  MULTIPLE_CHOICE: 'multiple-choice',
  TRUE_FALSE: 'true-false',
  SHORT_ANSWER: 'short-answer'
};

const QuestionCard = ({ question, index, handleAnswer, currentAnswer }) => {
  const renderMultipleChoice = () => (
    <div className="space-y-3">
      {question.choices.map((choice, i) => (
        <label
          key={i}
          className="flex items-center p-3 rounded-lg bg-white hover:bg-sand/30 cursor-pointer transition-colors"
        >
          <input
            type="radio"
            name={`question-${question.id}`}
            value={choice}
            checked={currentAnswer === choice}
            onChange={(e) => handleAnswer(question.id, e.target.value)}
            className="form-radio h-4 w-4 text-copper-dark focus:ring-copper-light"
          />
          <span className="ml-3 text-olive-dark">{choice}</span>
        </label>
      ))}
    </div>
  );

  const renderTrueFalse = () => (
    <div className="flex space-x-6">
      {['True', 'False'].map((option) => (
        <label
          key={option}
          className="flex items-center p-3 rounded-lg bg-white hover:bg-sand/30 cursor-pointer transition-colors"
        >
          <input
            type="radio"
            name={`question-${question.id}`}
            value={option}
            checked={currentAnswer === option}
            onChange={(e) => handleAnswer(question.id, e.target.value)}
            className="form-radio h-4 w-4 text-copper-dark focus:ring-copper-light"
          />
          <span className="ml-3 text-olive-dark">{option}</span>
        </label>
      ))}
    </div>
  );

  const renderShortAnswer = () => (
    <textarea
      value={currentAnswer || ''}
      onChange={(e) => handleAnswer(question.id, e.target.value)}
      placeholder="Type your answer here..."
      rows={3}
      className="w-full p-3 border border-olive-light rounded-lg focus:ring-2 focus:ring-copper-light focus:border-copper-light resize-none text-olive-dark"
    />
  );

  const renderQuestion = () => {
    switch (question.type) {
      case 'multiple-choice':
        return renderMultipleChoice();
      case 'true-false':
        return renderTrueFalse();
      case 'short-answer':
        return renderShortAnswer();
      default:
        return (
          <p className="text-red-500">
            Invalid question type: {question.type}
          </p>
        );
    }
  };

  return (
    <div className="bg-sand/30 rounded-lg p-6">
      <p className="mb-4 text-lg font-semibold text-olive-dark">
        <span className="mr-2">Question {index + 1}:</span>
        {question.question}
      </p>
      {renderQuestion()}
    </div>
  );
};

const ResultCard = ({
  question,
  index,
  userAnswer,
  correctAnswer,
  explanation
}) => {
  const isCorrect =
    userAnswer?.trim().toLowerCase() === correctAnswer?.trim().toLowerCase();

  return (
    <div className="p-6 bg-gray-50 rounded-lg">
      <h3 className="font-semibold mb-2 text-olive-dark">Question {index + 1}</h3>
      <p className="mb-2 text-olive-dark">{question}</p>
      <div className="space-y-2">
        <p
          className={`p-2 rounded ${
            isCorrect ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          }`}
        >
          Your Answer: {userAnswer || 'Not answered'}
        </p>
        <p className="p-2 bg-green-100 text-green-800 rounded">
          Correct Answer: {correctAnswer}
        </p>
        {explanation && (
          <p className="p-2 bg-blue-50 text-blue-800 rounded">
            Explanation: {explanation}
          </p>
        )}
      </div>
    </div>
  );
};

const TestTaker = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { topicId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [currentAnswers, setCurrentAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);

  useEffect(() => {
    const materials = location.state?.materials;
    const type = location.state?.type;

    if (!materials) {
      navigate(`/topics/${topicId}`);
      return;
    }

    // Get the appropriate questions array based on type
    let questionsArray = [];
    if (type === 'practice-test' && materials.practice_test?.questions) {
      questionsArray = materials.practice_test.questions;
    } else if (type === 'quiz' && materials.quiz?.questions) {
      questionsArray = materials.quiz.questions;
    } else if (type === 'flashcards' && materials.flashcards) {
      questionsArray = materials.flashcards.map((card, index) => ({
        id: index + 1,
        question: card.front,
        correctAnswer: card.back,
        type: QUESTION_TYPES.SHORT_ANSWER
      }));
    }

    console.log('Questions loaded:', questionsArray); // Debug line
    setQuestions(questionsArray);
  }, [location.state, navigate, topicId]);

  const handleAnswer = (questionId, answer) => {
    setCurrentAnswers(prev => ({ ...prev, [questionId]: answer }));
  };

  const calculateScore = () => {
    const totalQuestions = questions.length;
    const correctAnswers = questions.reduce((count, question) => {
      const userAnswer = currentAnswers[question.id];
      if (!userAnswer || !question.correctAnswer) return count;

      const isCorrect = userAnswer.trim().toLowerCase() === 
                       question.correctAnswer.trim().toLowerCase();
      return count + (isCorrect ? 1 : 0);
    }, 0);

    return (correctAnswers / totalQuestions) * 100;
  };

  const handleSubmit = () => {
    const finalScore = calculateScore();
    setScore(finalScore);
    setShowResults(true);
  };

  const handleFinish = () => {
    navigate(`/topics/${topicId}`);
  };

  return (
    <div className="min-h-screen bg-sand">
      <div className="max-w-3xl mx-auto px-4 py-8">
        {!showResults ? (
          <>
            <h2 className="text-2xl font-bold text-olive-dark mb-6">Take the Test</h2>
            <div className="space-y-6">
              {questions.map((question, index) => (
                <QuestionCard
                  key={question.id}
                  question={question}
                  index={index}
                  handleAnswer={handleAnswer}
                  currentAnswer={currentAnswers[question.id]}
                />
              ))}
            </div>
            <button
              onClick={handleSubmit}
              className="w-full mt-8 py-3 px-6 rounded-lg bg-copper-dark text-white font-semibold hover:bg-copper-light transition-colors"
            >
              Submit Test
            </button>
          </>
        ) : (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold text-olive-dark mb-6">Test Results</h2>
            <div className="mb-8 p-6 bg-sand rounded-lg">
              <p className="text-2xl font-semibold text-center text-olive-dark">
                Your Score: {score.toFixed(1)}%
              </p>
              <p className="text-center text-olive-dark">
                ({Math.round((score / 100) * questions.length)} out of {questions.length} correct)
              </p>
            </div>
            <div className="space-y-6">
              {questions.map((question, index) => (
                <ResultCard
                  key={question.id}
                  question={question.question}
                  index={index}
                  userAnswer={currentAnswers[question.id]}
                  correctAnswer={question.correctAnswer}
                  explanation={question.explanation}
                />
              ))}
            </div>
            <button
              onClick={handleFinish}
              className="w-full mt-8 py-3 px-6 rounded-lg bg-copper-dark text-white font-semibold hover:bg-copper-light transition-colors"
            >
              Back to Topic
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestTaker;