import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// idk why it doesnt work in env
const firebaseConfig = {
  apiKey: "AIzaSyCziQ2ZoKPgB1SWl3XtfzsDxUf_AIUOrVo",
  authDomain: "golai-8ff68.firebaseapp.com",
  projectId: "golai-8ff68",
  storageBucket: "golai-8ff68.appspot.com",
  messagingSenderId: "439982188256",
  appId: "1:439982188256:web:e48d3edb7deb86f9435285",
  measurementId: "G-4TXJYCTBXB"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export default app;
export { analytics, auth, db, storage };