import React, { useState } from 'react';
import { auth } from '../Firebase';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!email || !password || !confirmPassword) {
      setError('Please fill in all fields.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      console.error('Sign-up error:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('Email is already in use.');
      } else if (error.code === 'auth/weak-password') {
        setError('Password should be at least 6 characters.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Invalid email address.');
      } else {
        setError('Failed to create an account.');
      }
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (error) {
      console.error('Error signing up with Google:', error);
    }
  };

  return (
    <div className="min-h-screen bg-sand flex items-center justify-center px-4">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
        <div className="flex flex-col items-center">
          <FontAwesomeIcon 
            icon={faBook} 
            className="text-olive-dark w-12 h-12 mb-4"
          />
          <h1 className="text-3xl font-bold text-olive-dark text-center">Create Account</h1>
          {error && <p className="mt-2 text-red-600 text-center">{error}</p>}
        </div>
        
        <form onSubmit={handleSignUp} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-olive-dark mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-olive-light placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-copper-light focus:border-copper-light"
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-olive-dark mb-1">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Create a password"
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-olive-light placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-copper-light focus:border-copper-light"
                required
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-olive-dark mb-1">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-olive-light placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-copper-light focus:border-copper-light"
                required
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-olive-dark hover:bg-olive-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-olive-light transition-colors duration-200"
            >
              Create Account
            </button>
          </div>
        </form>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>

          <button
            onClick={handleGoogleSignUp}
            className="mt-4 w-full flex items-center justify-center py-2 px-4 border border-copper-dark rounded-md shadow-sm text-sm font-medium text-copper-dark bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-copper-light transition-colors duration-200"
          >
            <FontAwesomeIcon 
              icon={faGoogle} 
              className="h-5 w-5 mr-2"
            />
            Google
          </button>
        </div>

        <div className="text-center text-sm">
          <span className="text-gray-500">Already have an account? </span>
          <Link 
            to="/login" 
            className="font-medium text-copper-dark hover:text-copper-light transition-colors duration-200"
          >
            Sign in instead
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;